import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import axios from "axios";
const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
let SpFileActions = class SpFileActions extends Vue {
    _config;
    folders;
    currentAktPath;
    isDragEnter = false;
    uploadFiles = [];
    uploadFileNameObj = [];
    uploadProgress = 0;
    isSendingFiles = false;
    isFilesSent = false;
    currentTry = 0;
    toUploadFolder = "";
    newCreatedFolderName = "";
    overWriteFile = false;
    get dropZoneClass() {
        return { isDragEnter: this.isDragEnter };
    }
    mounted() {
        // const dropzone: any = this.$refs.dropzone;
        window.addEventListener("dragenter", (e) => {
            this.isDragEnter = true;
            e.dataTransfer.dropEffect = "copy";
            e.dataTransfer.effectAllowed = "copy";
        });
        window.addEventListener("dragleave", (e) => {
            this.isDragEnter = false;
            e.dataTransfer.dropEffect = "copy";
            e.dataTransfer.effectAllowed = "copy";
            e.preventDefault();
        });
        window.addEventListener("dragover", (e) => {
            this.isDragEnter = true;
            e.dataTransfer.dropEffect = "copy";
            e.dataTransfer.effectAllowed = "copy";
            e.preventDefault();
        });
        window.addEventListener("drop", (e) => {
            this.uploadProgress = 0;
            this.isDragEnter = false;
            e.dataTransfer.dropEffect = "copy";
            e.dataTransfer.effectAllowed = "copy";
            e.preventDefault();
            this.addFiles(e);
        });
    }
    addFiles(e) {
        const files = e.dataTransfer?.files || e.target?.files || [];
        this.uploadFiles = [];
        this.uploadFileNameObj = [];
        this.uploadProgress = 0;
        if (files.length > 0) {
            this.$modal.show("FilesToUploadToSpV2");
        }
        for (const i of files) {
            this.uploadFiles.push(i);
            const splitArr = this.$_splitName(i.name);
            const name = splitArr.nuname;
            const ext = splitArr.format;
            this.uploadFileNameObj.push({ name, ext });
        }
    }
    uploadProgressChanged(nv, ov) {
        if (nv == 100) {
            // console.log(this.uploadFiles);
            this.uploadFiles = [];
            this.uploadFileNameObj = [];
            this.uploadProgress = 0;
            this.$modal.hide("FilesToUploadToSpV2");
        }
    }
    async fetchWithTimeout(timeout = 2000) {
        return await Promise.race([
            new Promise((resolve) => setTimeout(() => resolve(null), timeout)),
        ]);
    }
    async SendFilesNew() {
        if (this.isSendingFiles)
            return;
        const maxRetries = 5;
        const retryDelay = 5000;
        this.isSendingFiles = false;
        this.isFilesSent = false;
        this.currentTry = 1;
        this.SET(["uploadInfo", ""]);
        this.SET(["isUploadingOfflineFiles", true]);
        const trySendFiles = async () => {
            console.log("trySendFiles");
            while (this.currentTry <= maxRetries) {
                let sendOperation = new Promise((resolve) => resolve("pending"));
                if (this.isFilesSent)
                    sendOperation = new Promise((resolve) => resolve("success"));
                else if (!this.isSendingFiles)
                    sendOperation = this.SendActualFiles();
                const timeoutPromise = new Promise((resolve) => setTimeout(() => resolve("timeout"), retryDelay));
                const result = await Promise.race([sendOperation, timeoutPromise]);
                if (result === "success") {
                    console.log("Files sent successfully");
                    break;
                }
                else if (result === "error") {
                    console.log(`Attempt ${this.currentTry}: Operation failed. Retrying...`);
                    this.SET([
                        "uploadInfo",
                        `${this.currentTry}: Bitte Warten...`,
                    ]);
                    this.currentTry += 1;
                    await new Promise((resolve) => setTimeout(resolve, retryDelay));
                    // last try:
                    if (this.currentTry > maxRetries)
                        this.$notify({
                            group: "notifications",
                            text: "Fehler beim Hochladen. Bitte in 5 Minuten erneut versuchen.",
                            type: "error",
                        });
                    continue;
                }
                else if (result === "pending") {
                    console.log(`Attempt ${this.currentTry}: Operation pending.`);
                    await new Promise((resolve) => setTimeout(resolve, retryDelay));
                }
                else if (result === "timeout") {
                    if (this.isSendingFiles) {
                        console.log(`is sending files...`);
                    }
                    else {
                        console.log(`Attempt ${this.currentTry}: Operation timed out. Retrying...`);
                        this.SET([
                            "uploadInfo",
                            `${this.currentTry}: Bitte Warten..`,
                        ]);
                        this.currentTry += 1;
                        await new Promise((resolve) => setTimeout(resolve, retryDelay));
                    }
                }
                else if (result === "nofiles") {
                    console.log("No files to upload. Exiting...");
                    break;
                }
            }
        };
        await trySendFiles();
        this.isSendingFiles = false;
        this.SET(["isUploadingOfflineFiles", false]);
        this.$modal.hide("FilesToUploadToSpV2");
        this.$emit("refresh");
    }
    async SendActualFiles() {
        if (this.uploadFiles.length === 0)
            return "nofiles";
        try {
            this.SET(["uploadInfo", `${this.currentTry}...`]);
            const formData = new FormData();
            for (let i in this.uploadFiles)
                formData.append("spFiles", this.uploadFiles[i], this.uploadFileNameObj[i].name + this.uploadFileNameObj[i].ext);
            const config = await this._config();
            config.headers.overwrite = this.overWriteFile;
            config.headers.filepath = this.determineFilePath();
            config.onUploadProgress = (progressEvent) => {
                this.isSendingFiles = true;
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                this.SET(["uploadInfo", `${this.currentTry}: ${progress}%`]);
            };
            await axios.post(this.$env.mainurl + "api/v2/spsavefile", formData, config);
            this.isSendingFiles = false;
            this.isFilesSent = true;
            return "success";
        }
        catch (error) {
            console.error(error);
            this.isSendingFiles = false;
            return "error";
        }
    }
    determineFilePath() {
        if (this.toUploadFolder === "create_new_folder") {
            return `${this.currentAktPath}/${this.newCreatedFolderName}`.trim();
        }
        else {
            return `${this.currentAktPath}/${this.toUploadFolder}`;
        }
    }
};
__decorate([
    Action
], SpFileActions.prototype, "_config", void 0);
__decorate([
    Prop()
], SpFileActions.prototype, "folders", void 0);
__decorate([
    Prop()
], SpFileActions.prototype, "currentAktPath", void 0);
__decorate([
    Watch("uploadProgress")
], SpFileActions.prototype, "uploadProgressChanged", null);
SpFileActions = __decorate([
    Component({})
], SpFileActions);
export default SpFileActions;
