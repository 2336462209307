import "core-js/modules/es.array.splice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('dynamic-modal-container', {
    attrs: {
      "title": "PDF aufteilen"
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "form"
  }, [_c('label', {
    attrs: {
      "id": "lbl"
    }
  }, [_vm._v("Seitenzahlen, an denen aufgeteilt werden soll:")]), _c('ul', {
    staticClass: "page-list"
  }, _vm._l(_vm.pages, function (p, i) {
    return _c('li', {
      key: i
    }, [_c('input', {
      attrs: {
        "type": "number",
        "min": i > 0 ? _vm.pages[i - 1] + 1 : 1,
        "max": i < _vm.pages.length ? _vm.pages[i + 1] - 1 : Infinity,
        "aria-labelledby": "lbl"
      },
      domProps: {
        "value": p
      },
      on: {
        "change": function change($event) {
          _vm.pages.splice(i, 1, parseInt($event.target.value));
        }
      }
    }), _c('button', {
      staticClass: "btn _clear nomargin",
      on: {
        "click": function click($event) {
          return _vm.removePageSplit(i);
        }
      }
    }, [_c('i', {
      staticClass: "icon-trashbin-thin"
    })])]);
  }), 0), _c('button', {
    staticClass: "btn _clear nomargin",
    on: {
      "click": _vm.addPageSplit
    }
  }, [_vm._v("+")])]), _vm.errorMessage ? _c('div', [_c('span', {
    staticClass: "color-red"
  }, [_vm._v(_vm._s(_vm.errorMessage))])]) : _vm._e(), !_vm.loading ? _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.splitPdf
    }
  }, [_vm._v("Pdf aufteilen")]) : _c('div', {
    staticClass: "tac"
  }, [_c('span', {
    staticClass: "loading-spinner inline"
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };