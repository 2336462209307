var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.eTag ? _c('a', {
    staticClass: "btn _small _bordered color-green bold",
    attrs: {
      "href": _vm.makeNativeOneDriveLink
    }
  }, [_vm._v("OneDrive SYNC")]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };