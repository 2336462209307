import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', [_c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "SpFileRenamer",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    },
    on: {
      "before-open": _vm.beforeOpenSpFileRenamer
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_c('span', [_vm._v("  Datei umbenennen")]), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('SpFileRenamer');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Ordner")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.toRenamePath))])]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Original Dateiname")]), _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.toRenameFile))])]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Dateiformat")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newRenameFileFormat,
      expression: "newRenameFileFormat"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.newRenameFileFormat
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.newRenameFileFormat = $event.target.value;
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Neuer Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newRenameFile,
      expression: "newRenameFile"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.newRenameFile
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.newRenameFile = $event.target.value;
      }
    }
  })]), _vm.isFilenamePossible ? _c('div', {
    staticClass: "tac color-red"
  }, [_c('div', {
    staticClass: "span"
  }, [_vm._v("ACHTUNG: Dateinamen dürfen folgende Symbole nicht enthalten:")]), _c('pre', [_vm._v("/ \\ : \"")])]) : _vm._e(), _vm.isWaitingForRename ? _c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "loading-spinner _small"
  })]) : _vm._e()]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('SpFileRenamer');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        !_vm.isWaitingForRename && _vm.renameFile();
      }
    }
  }, [_vm._v("Datei umbenennen")])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "SpFileDeleter",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    },
    on: {
      "before-open": _vm.beforeOpenSpFileDeleter
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_c('span', [_vm._v("  Datei löschen")]), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('SpFileDeleter');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _vm.toDeleteFiles.length ? _c('div', {
    staticClass: "block"
  }, [_c('h3', [_vm._v("Sicher Dateien löschen?")]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label _small"
  }, [_vm._v("Dateien")]), _c('div', {
    staticClass: "input"
  }, [_c('ul', _vm._l(_vm.toDeleteFiles, function (file) {
    return _c('li', {
      staticClass: "padding _small"
    }, [_vm._v("• " + _vm._s(file.name))]);
  }), 0)])]), _vm.isWaitingForDeleting ? _c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "loading-spinner _small"
  })]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('SpFileDeleter');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        !_vm.isWaitingForDeleting && _vm.deleteSpFile();
      }
    }
  }, [_vm._v("Datei löschen")])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };