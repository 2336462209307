import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card min420"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Sharepoint-Dateien " + _vm._s(_vm.consenseNr))]), _c('div', {
    staticClass: "fr flex gap-2"
  }, [_vm.isContentLoading ? _c('div', {
    staticClass: "loading-spinner _small"
  }) : _vm._e(), _vm._isAdmin && _vm.preferredNotificationTransmission ? _c('small', {
    staticClass: "color-lightgrey"
  }, [_vm._v("präf. Nachrichten per: " + _vm._s(_vm.preferredNotificationTransmission))]) : _vm._e()])]), _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "topRow fr"
  }, [_vm.currentAktPath ? _c('div', {
    staticClass: "btn _small _bordered bold",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('FilesToUploadToSpV2');
      }
    }
  }, [_vm._v("Dateien Hochladen")]) : _vm._e(), _vm.currentAktPath ? _c('div', {
    staticClass: "btn _small _bordered bold",
    on: {
      "click": function click($event) {
        return _vm.gotToSp();
      }
    }
  }, [_vm._v("Zum-Sharepoint")]) : _vm._e(), _c('OneDriveLink', {
    attrs: {
      "eTag": (_vm.info || {}).eTag || '',
      "fullAktName": _vm.mainAktpath
    }
  })], 1), _c('div', {
    staticClass: "clear"
  }), _c('div', {
    staticClass: "padding tac"
  }, [_c('ZipDownFolder', {
    attrs: {
      "folderName": _vm.mainAktpath,
      "folderId": _vm.mainUniqueId,
      "currendId": _vm.consenseNr,
      "isForCrm": true,
      "downloadableSvItems": _vm.downloadableSvItems,
      "text": "gesamten Ordner als Zip",
      "btnSize": "_medium",
      "btnColor": "_bordered"
    }
  })], 1), _c('div', {
    staticClass: "clear"
  }), _c('SpFileActions', {
    on: {
      "refresh": function refresh($event) {
        return _vm.callFolder();
      }
    }
  }), _c('SpFileUpload', {
    attrs: {
      "folders": _vm.folders,
      "currentAktPath": _vm.currentAktPath
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.callFolder();
      }
    }
  }), _c('CrmFilePreview', {
    attrs: {
      "files": _vm.sortedFiles
    }
  }), _vm.isContentLoading && _vm.content.length == 0 ? _c('span', [_c('div', {
    staticClass: "loading-spinner"
  })]) : _c('span', [_c('div', {
    staticClass: "_topline"
  }, [_c('div', [_vm.currentAktPath.includes('/') ? _c('a', {
    staticClass: "link bold",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.callBackFolder();
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-left"
  }), _c('span', [_vm._v("Zurück  ")])]) : _vm._e()]), _c('div', {
    staticClass: "bold"
  }, [_c('span', [_vm._v(_vm._s(_vm.currentAktPath.split('/')[0].substring(0, 10)) + " /")]), _vm._l(_vm.currentAktPath.split('/').slice(1), function (item) {
    return _c('span', [_c('span', [_vm._v(_vm._s(item) + " /")])]);
  })], 2), _c('div', [_vm.filteredSelectedFiles.length ? _c('span', [_c('v-popover', {
    staticClass: "inl",
    attrs: {
      "offset": "3",
      "placement": "bottom"
    }
  }, [_c('a', {
    staticClass: "btn _small _bordered tooltip-target color-blue"
  }, [_vm._v("Aktion  "), _c('i', {
    staticClass: "icon-arrow-down"
  }), _vm.isLoading ? _c('div', {
    staticClass: "loading-spinner inline"
  }) : _vm._e()]), _c('template', {
    slot: "popover"
  }, [_c('ul', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "chooseList"
  }, [_c('li', [_c('a', {
    staticClass: "chooseList_a color-red",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('SpFileDeleter', {
          files: _vm.filteredSelectedFiles
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  }), _c('span', [_vm._v("  Ausgewählte Dateien löschen")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        !_vm.isLoading && _vm.downloadSelectedFiles(_vm.filteredSelectedFiles);
      }
    }
  }, [_c('i', {
    staticClass: "icon-download"
  }), _c('span', [_vm._v("  Ausgewählte Dateien runterladen")])])])])])], 2)], 1) : _vm._e(), _c('div', {
    staticClass: "inl"
  }, [_c('a', {
    staticClass: "btn _small _bordered",
    on: {
      "click": function click($event) {
        return _vm.callFolder();
      }
    }
  }, [_vm._v("Neu laden")])]), _c('v-popover', {
    staticClass: "inl",
    attrs: {
      "offset": "3",
      "placement": "bottom"
    }
  }, [_c('a', {
    staticClass: "btn _small _bordered tooltip-target"
  }, [_vm._v("Ansicht")]), _c('template', {
    slot: "popover"
  }, [_c('ul', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "chooseList"
  }, [_c('li', [_c('a', {
    staticClass: "chooseList_a",
    on: {
      "click": function click($event) {
        _vm.whichView = 'listView';
      }
    }
  }, [_c('i', {
    staticClass: "icon-list"
  }), _c('span', [_vm._v("  Listenansicht")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    on: {
      "click": function click($event) {
        _vm.whichView = 'boxView';
      }
    }
  }, [_c('span', [_vm._v("  Kachelansicht")])])])])])], 2), _c('v-popover', {
    staticClass: "inl",
    attrs: {
      "offset": "3",
      "placement": "bottom"
    }
  }, [_c('a', {
    staticClass: "btn _small _bordered tooltip-target"
  }, [_vm._v("Sortieren")]), _c('template', {
    slot: "popover"
  }, [_c('ul', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "chooseList"
  }, [_c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'created' && _vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'created';
        _vm.reverse = true;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-top"
  }), _c('span', [_vm._v("  erstellt am")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'created' && !_vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'created';
        _vm.reverse = false;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-down"
  }), _c('span', [_vm._v("  erstellt am")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'modified' && _vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'modified';
        _vm.reverse = true;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-top"
  }), _c('span', [_vm._v("  geändert am")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'modified' && !_vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'modified';
        _vm.reverse = false;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-down"
  }), _c('span', [_vm._v("  geändert am")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'name' && _vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'name';
        _vm.reverse = false;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-top"
  }), _c('span', [_vm._v("  Name")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'name' && !_vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'name';
        _vm.reverse = true;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-down"
  }), _c('span', [_vm._v("  Name")])])])])])], 2)], 1)]), _c('ul', {
    staticClass: "foldercontent"
  }, _vm._l(_vm.folders, function (item, ind) {
    return item.name !== 'vertraulich' || _vm._crmMayEditVertraulich ? _c('li', {
      staticClass: "_file",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.callFolder(item.name);
        }
      }
    }, [_c('div', {
      staticClass: "_file__outerflex"
    }, [_vm._m(0, true), _c('div', {
      staticClass: "_file__details"
    }, [_c('div', {
      staticClass: "_file__name"
    }, [_vm._v(_vm._s(item.name))]), _c('small', {
      staticClass: "_file__stats"
    }, [_vm._v("(" + _vm._s(item.fileCount) + " Dateien) / " + _vm._s(_vm.$_toHumanFileSize(item.size)))])])]), _c('div', {
      staticClass: "_file__actions",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('v-popover', {
      attrs: {
        "offset": "3",
        "placement": "left"
      }
    }, [_c('a', {
      staticClass: "tooltip-target"
    }, [_vm.loadingZips[item.id] ? _c('div', {
      staticClass: "loading-spinner inline"
    }) : _c('i', {
      staticClass: "icon-list"
    })]), _c('template', {
      slot: "popover"
    }, [_c('ul', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "chooseList"
    }, [_c('li', [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "chooseList_a",
      attrs: {
        "target": "_blank",
        "href": item.downloadUrl
      }
    }, [_c('ZipDownFolder', {
      attrs: {
        "folderName": item.name,
        "folderId": item.id,
        "currendId": _vm.consenseNr,
        "isForCrm": true,
        "ownContent": true,
        "preserveTime": true,
        "webUrl": item.webUrl,
        "currentAktPath": _vm.currentAktPath
      },
      on: {
        "loadingZipFolder": _vm.loadingZipFolder
      }
    }, [_c('i', {
      staticClass: "icon-download"
    }), _c('span', [_vm._v("  Ordner runterladen")])])], 1)])])])], 2)], 1)]) : _vm._e();
  }), 0), _c('ul', {
    staticClass: "foldercontent",
    class: {
      _boxView: _vm.whichView == 'boxView',
      _listView: _vm.whichView == 'listView'
    }
  }, [_vm.files.length == 0 ? _c('li', {
    staticClass: "_file"
  }, [_c('span', [_c('div', {
    staticClass: "padding"
  }, [_vm._v("keine Daten vorhanden")]), !_vm.mainAktpath ? _c('div', {
    staticClass: "padding bold"
  }, [_vm._v("Möglicherweise existiert der Ordner nicht")]) : _vm._e()])]) : _vm._e(), _vm._l(_vm.sortedFiles, function (item, ind) {
    return _c('li', {
      staticClass: "_file",
      class: {
        _selected: _vm.selectedFiles.includes(item.id)
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$modal.show('CrmFilePreview', {
            idx: ind
          });
        }
      }
    }, [_vm.whichView == 'listView' ? _c('div', {
      staticClass: "_file__outerflex _listViewItem"
    }, [_c('div', {
      staticClass: "_file__select",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('v-checkbox', {
      attrs: {
        "item": item.id
      },
      model: {
        value: _vm.selectedFiles,
        callback: function callback($$v) {
          _vm.selectedFiles = $$v;
        },
        expression: "selectedFiles"
      }
    })], 1), _c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: _vm.makeImageHtml(item),
          placement: 'right-end',
          offset: 10,
          delay: {
            show: 150,
            hide: 300
          }
        },
        expression: "{ content: makeImageHtml(item), placement: 'right-end', offset: 10, delay: { show: 150, hide: 300 } }"
      }],
      staticClass: "_file__thumbnail"
    }, [_c('div', {
      staticClass: "embeded"
    }, [_c('div', {
      staticClass: "img",
      style: 'background-image:url(' + _vm.$_makeImgUrl(item.id, 256, _vm.$_extension(item.name), _vm.token) + ')' + ';background-size: contain;',
      attrs: {
        "width": "64px",
        "height": "64px"
      }
    })])]), _c('div', {
      staticClass: "_file__details"
    }, [_c('div', {
      staticClass: "_file__name"
    }, [_vm._v(_vm._s(item.name))]), _c('small', {
      staticClass: "_file__stats"
    }, [_vm._v(_vm._s(_vm.$_toHumanFileSize(item.size)) + " / erstellt: " + _vm._s(_vm.$dayjs(item.created).format('DD.MM.YYYY HH:mm'))), _vm.$dayjs(item.created).format('DD.MM.YYYY HH:mm') != _vm.$dayjs(item.modified).format('DD.MM.YYYY HH:mm') ? _c('span', [_vm._v(" / geändert: " + _vm._s(_vm.$dayjs(item.modified).format('DD.MM.YYYY HH:mm')))]) : _vm._e()])])]) : _vm._e(), _vm.whichView == 'boxView' ? _c('div', {
      staticClass: "_file__outerflex _boxViewItem"
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: _vm.makeImageHtml(item),
          placement: 'right-end',
          offset: 10,
          delay: {
            show: 150,
            hide: 300
          }
        },
        expression: "{ content: makeImageHtml(item), placement: 'right-end', offset: 10, delay: { show: 150, hide: 300 } }"
      }],
      staticClass: "_file__thumbnail"
    }, [_c('div', {
      staticClass: "embeded"
    }, [_c('div', {
      staticClass: "img",
      style: 'background-image:url(' + _vm.$_makeImgUrl(item.id, 256, _vm.$_extension(item.name), _vm.token) + ')' + ';background-size: contain;',
      attrs: {
        "width": "64px",
        "height": "64px"
      }
    })])]), _c('div', {
      staticClass: "_file__details"
    }, [_c('div', {
      staticClass: "_file__name"
    }, [item.name.length <= 42 ? _c('span', {
      staticClass: "narroLetters"
    }, [_vm._v(_vm._s(item.name))]) : item.name.length <= 52 ? _c('span', {
      staticClass: "narroLetters condensed"
    }, [_vm._v(_vm._s(item.name))]) : item.name.length > 52 ? _c('span', {
      staticClass: "narroLetters condensed"
    }, [_vm._v(_vm._s(item.name.substring(0, 24)) + ".. "), _c('br'), _vm._v(" .." + _vm._s(item.name.substring(item.name.length - 24)))]) : _vm._e()])])]) : _vm._e(), _c('div', {
      staticClass: "_file__actions",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('div', {
      staticClass: "_file__select _boxViewItem",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('v-checkbox', {
      attrs: {
        "item": item.id
      },
      model: {
        value: _vm.selectedFiles,
        callback: function callback($$v) {
          _vm.selectedFiles = $$v;
        },
        expression: "selectedFiles"
      }
    })], 1), _c('v-popover', {
      attrs: {
        "offset": "3",
        "placement": "left"
      }
    }, [_c('a', {
      staticClass: "link pointer tooltip-target"
    }, [_c('i', {
      staticClass: "icon-list"
    })]), _c('template', {
      slot: "popover"
    }, [_c('ul', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "chooseList"
    }, [item.name.split('.').pop().toLowerCase() === 'pdf' ? _c('li', [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "chooseList_a",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.showSplitDialog(item);
        }
      }
    }, [_vm._v("Pdf aufteilen")])]) : _vm._e(), _c('li', [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "chooseList_a",
      attrs: {
        "target": "_blank",
        "href": item.downloadUrl
      }
    }, [_c('i', {
      staticClass: "icon-download"
    }), _c('span', [_vm._v("  Datei runterladen")])])]), _c('li', [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "chooseList_a",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$modal.show('SpFileRenamer', {
            file: item
          });
        }
      }
    }, [_c('i', {
      staticClass: "icon-label"
    }), _c('span', [_vm._v("  Datei umbenennen")])])]), _c('li', [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "chooseList_a",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$modal.show('SpFileDeleter', {
            files: [item]
          });
        }
      }
    }, [_c('i', {
      staticClass: "icon-trashbin"
    }), _c('span', [_vm._v("  Datei löschen")])])])])])], 2)], 1)]);
  })], 2)])], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "_file__thumbnail"
  }, [_c('i', {
    staticClass: "icon-folder"
  })]);
}];
export { render, staticRenderFns };